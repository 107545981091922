<template>
    <div class="details-box">
        <!-- TAB -->
        <el-tabs v-model="activeName">
            <el-tab-pane label="个人信息表" name="personal">
                <Info v-if="activeName === 'personal'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Info from '../../../components/Details/Transfer/Info.vue'
export default {
    name: 'TransferDetails',
    components: {
        Info
    },
    data() {
        return {
            activeName: 'personal',
        }
    },
    methods: {
    },
}
</script>

<style lang="less" scoped>

</style>
